<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { flowService } from 'src/services'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'RestartFlowModal',

  components: {
    SqDialog
  },

  props: {
    page: {},
    flowName: {},
    flowId: {},
    payload: {}
  },

  emits: ['success'],

  setup (props, { emit }) {
    const $store = useStore();
    const isLoading = ref(false);

    function restartFlow() {
      isLoading.value = true;
      flowService.startFlow(props.flowId, (data) => {
        isLoading.value = false;
        try {
          $store.dispatch('alert/success', {message: 'flow.executions.detail.modal.restartFlowSuccess'}, { root: true });
          emit('success', data);
        } catch (e) {
          console.error(e);
        }
      });
    }

    return {
      isLoading,
      restartFlow
    }
  }
})
</script>

<template>
  <sq-dialog
    type="update"
    :save-button-label="$t('general.confirm')"
    :loading="isLoading"
    @save="restartFlow"
  >
    <template #title>
      {{ $t('flow.executions.detail.modal.restartFlowTitle', {flowName: flowName}) }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('flow.executions.detail.modal.restartFlowDesc') }}
      </div>
    </template>
  </sq-dialog>
</template>
