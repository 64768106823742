<script>
  import { defineComponent } from 'vue'
  import { date } from 'quasar'

  export default defineComponent({
    name: 'DateDisplay',
    props: {
      startTime: {
        type: [String, Number],
        required: true
      },
      endTime: {
        type: [String, Number],
        required: false
      },
      onlyDate: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: Boolean,
        default: true
      },
      format: {
        type: String,
        default: "short"
      },
      offset: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fromNow: false,
        times: {
          d: {
            val: 0,
            lab: this.$t(`general.time.${this.$props.format}.days`)
          },
          h: {
            val: 0,
            lab: this.$t(`general.time.${this.$props.format}.hours`)
          },
          m: {
            val: 0,
            lab: this.$t(`general.time.${this.$props.format}.minutes`)
          },
          s: {
            val: 0,
            lab: this.$t(`general.time.${this.$props.format}.seconds`)
          }
        }
      }
    },
    computed: {
      duration() {
        let offsetEnd = 0;
        let offsetStart = 0;
        if(this.$props.offset) {
          offsetEnd = (new Date(this.$props.endTime).getTimezoneOffset() * 60000);
          offsetStart = (new Date(this.$props.startTime).getTimezoneOffset() * 60000);
        }
        return this.convertMs(Math.abs(
          new Date(this.$props.endTime).getTime() - offsetEnd -
          new Date(this.$props.startTime).getTime() - offsetStart
        ));
      },
      durationUntilNow() { // if param endTime isn't set, calculate the difference between now and startTime
        let offsetEnd = 0;
        if(this.$props.offset) offsetEnd = (new Date(this.$props.startTime).getTimezoneOffset() * 60000);
        this.fromNow = true;
        return this.convertMs(Math.abs(
          new Date().getTime() - new Date(this.$props.startTime).getTime() - offsetEnd
        ));
      }
    },
    methods: {
      getTimes(onlyDate, isTooltip) {
        let t = null
        try {
          t = new Date(this.$props.startTime).toISOString()
        } catch(e) {
          // Date error would fall back 01.01.1970 01:00:00 - we show a suitable info text instead
          return this.$t('general.time.invalid')
        }
        /*
          // If there is ever a case in the future we need two dates to be displayed in the tooltip, then we can use this code. We may need to add a prop to it.
          if(isTooltip && !onlyDate) {
          const start = this.$props.startTime;
          const end = typeof this.$props.endTime !== 'undefined' ? this.$props.endTime : new Date().getTime();
          return date.formatDate(start, 'DD.MM.YYYY HH:mm:ss') + ' - ' + date.formatDate(end, 'DD.MM.YYYY HH:mm:ss');
        }*/
        if(onlyDate) {
          const format = 'DD.MM.YYYY HH:mm:ss';
          if(this.$props.offset) {
            // We need to calculate out the local offset, as that was handled already by the server
            let offsetInMinutes = new Date(t).getTimezoneOffset();
            const correctedDate = date.subtractFromDate(t, { minutes: -offsetInMinutes })
            return date.formatDate(correctedDate, format)
          } else {
            return date.formatDate(t, format)
          }
        } else if(typeof this.$props.endTime !== 'undefined') {
          return this.duration
        } else {
          return this.durationUntilNow
        }
      },
      convertMs(ms) {
        if(typeof ms === 'undefined' || ms === 0 || ms === null || isNaN(ms)) return '-'

        const dayInSec = (3600 * 24)
        let sec = ms / 1000

        this.times.d.val = Math.floor((sec / dayInSec))
        this.times.h.val = Math.floor(((sec % dayInSec) / 3600))
        this.times.m.val = Math.floor(((sec % 3600) / 60))
        this.times.s.val = Math.floor((sec % 60))

        return this.displayTime(this.times)
      },
      displayTime() {
        let output = ''

        for (let prop in this.times) {
          if(this.times[prop].val > 0) {
            output += this.times[prop].val += this.times[prop].lab + ' '
          }
        }
        if(output === ' ' || output === '') output = '0s';

        if(this.fromNow) {
          return this.$t('general.time.timeAgo', {timeData: output})
        } else {
          return output
        }
      }
    }
  })
</script>

<template>
  <span>
    {{ getTimes(onlyDate, false) }}
    
    <!-- to disable the tooltip, use `:tooltip="false"` -->
    <template v-if="tooltip">
      <q-tooltip
        anchor="top middle" self="bottom middle"
        v-if='!onlyDate || $q.platform.is.mobile'
        class="app-tooltip-mobile"
      >
        {{ this.getTimes(true, true) }}
      </q-tooltip>
    </template>
  </span>
</template>
