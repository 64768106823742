<script>
  import { defineComponent } from 'vue'
  import JsonEditor from 'components/JsonEditor.vue'
  import LoadingIndicator from 'components/LoadingIndicator'
  import StatisticCard from 'components/StatisticCard'
  import { useDialogPluginComponent } from 'quasar'
  import { supervisorService } from 'src/services'

  export default defineComponent({
    name: 'RestartWorkerModal',
    components: {
      LoadingIndicator,
      StatisticCard,
      JsonEditor
    },
    emits: [
      ...useDialogPluginComponent.emits
    ],
    props: ["supervisor"],
    setup () {
      const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();

      return {
        dialogRef,
        onDialogHide,
        restartSupervisor() {
          onDialogOK();
          supervisorService.restartSupervisor()
            .then(res => {
              if (!res.data.success) {
                this.$store.dispatch('alert/error', 'dashboard.supervisor.restartFailed', { root: true });
              }
            })
            .catch(err => {
              if(err.toString().includes("code 403")) {
                this.$store.dispatch('alert/error', 'dashboard.supervisor.accessDenied', { root: true });
              }
              console.error(err)
            })
        },
        onCancelClick: onDialogCancel
      }
    },
    data () {
      return {
        confirmDelete: "",
        isLoading: false
      }
    }
  })
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card style="width: 1024px; max-width: 90vw;" class="q-dialog-plugin q-pa-sm">
      <q-form @submit="restartSupervisor()">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6"><q-avatar icon="restart_alt" color="primary" class="q-mr-sm" text-color="white" />{{ $t('dashboard.supervisor.headline') }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <loading-indicator v-if="isLoading" />

        <q-card-section v-if="!isLoading">
          <div class="q-ml-sm q-mt-md">
            {{ $t('dashboard.supervisor.restartConfirm') }}
          </div>
        </q-card-section>

        <q-card-section>
          <q-card-actions align="right">
            <q-btn flat :label="$t('general.cancel')" color="secondary" v-close-popup />
            <q-btn flat :label="$t('dashboard.supervisor.restart')" color="primary" type="submit" data-cy="buttonSubmit" />
          </q-card-actions>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>
