import client from '../helpers/authenticated-axios'

export const supervisorService = {
  getSupervisor() {
    return client().get('/supervisors');
  },
  restartGroup(groupName) {
    return client().post('/supervisors/start/' + groupName);
  },
  stopGroup(groupName) {
    return client().post('/supervisors/stop/' + groupName);
  },
  restartSupervisor(callback) {
    return client().get('/supervisors/restart');
  },
};
