<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import JsonEditor from 'components/JsonEditor.vue'
import DateDisplay from 'components/DateDisplay.vue'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'ViewFileModal',

  components: {
    SqDialog,
    JsonEditor,
    DateDisplay
  },

  props: {
    path: {},
    file: {},
    base64Encode: {},
    base64Decode: {}
  },

  emits: ['saved'],

  data () {
    return {
      isLoading: false,
      fileContentEdit: '',
      disableEditor: false
    }
  },

  computed: {
    ...mapGetters('fileManager', [
      'isRefreshing',
      'fileContent'
    ]),

    fileSize() {
      if (!this.fileContent) return ''

      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0, n = parseInt(this.fileContent.meta_data.size, 10) || 0;
      while(n >= 1024 && ++l){
        n = n/1024;
      }
      return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },

    fileExtension() {
      if (!this.fileContent) return ''
      if(this.fileContent?.meta_data?.path) return this.fileContent.meta_data.path.slice(this.fileContent.meta_data.path.lastIndexOf(".") + 1);
      return this.file.basename.slice(this.file.basename.lastIndexOf(".") + 1); // Fallback to file basename
    },

    fileDate() {
      if (!this.fileContent) return ''

      return new Date(this.fileContent.meta_data.timestamp).toISOString();
    },

    isImage() {
      if (!this.fileContent) return false

      const allowedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
      let isImage = false;
      allowedImageFormats.forEach(fileFormat => {
        if(typeof this.fileContent.meta_data.path !== "undefined" && this.fileContent.meta_data.path.toLowerCase().endsWith("." + fileFormat)) {
          isImage = true;
        }
      });
      return isImage;
    }
  },

  methods: {
    ...mapActions('fileManager', ['clearFile']),

    initialize() {
      this.clearFile();
      this.$store.dispatch('fileManager/readFile', {path: this.path, file: this.file});
    },

    resetState() {
      this.isLoading = false;
      this.fileContentEdit = '';
      this.disableEditor = false;
      this.clearFile();
    },

    editFile() {
      this.isLoading = true;
      let fileContent = this.base64Encode(this.fileContentEdit);
      this.$store.dispatch('fileManager/editFile', {path: this.path, file: {name: this.file.basename, content: fileContent}});

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        this.isLoading = false;
        this.$emit('saved');
      } ,1000)
    }
  },

  watch: {
    fileContent() {
      if (this.fileContent !== null) {
        this.fileContentEdit = this.isImage ? this.fileContent.content : this.base64Decode(this.fileContent.content);
      }
    }
  }
})
</script>

<template>
  <sq-dialog
    type="viewFile"
    :loading="!fileContent || isLoading"
    size="lg"
    @hide="resetState"
    @show="initialize"
  >
    <template #title>
      {{ file.basename }}
    </template>

    <template #content>
      <div class="q-ml-sm q-mt-md">
        <dl class="app-file-description-list">
          <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.size') }}</dt>
          <dd class="q-mb-sm">
            {{ fileSize }}
          </dd>
          <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.date') }}</dt>
          <dd class="q-mb-sm">
            <date-display v-if="fileDate" :start-time="fileDate" only-date />
          </dd>
          <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.visibility') }}</dt>
          <dd>
            {{ fileContent?.visibility }}
          </dd>
        </dl>
        <template v-if="fileContent && isImage"> <!-- Image-->
          <q-img
            :src="'data:image/'+ fileExtension +';base64,' + fileContent?.content"
            :alt="fileContent?.meta_data.path" :title="fileContent?.meta_data.path"
            fit="scale-down"
            style="max-height: 75vh;"
          />
        </template>
        <template v-if="fileContent && !isImage"> <!-- File -->
          <div class="text-h6 q-mt-md">{{ $t('browser.fileContent.content') }}</div>
          <json-editor
            :lang="fileExtension"
            v-model="fileContentEdit"
            :disabled="disableEditor"
            :no-validation="true"
            wrapped
          />
        </template>
      </div>
    </template>

    <template #actions>
      <q-btn flat :label="$t('general.close')" color="secondary" v-close-popup data-cy="closeModal" />
      <q-btn
        v-if="fileContent && !isImage"
        flat
        :disabled="disableEditor"
        :label="$t('browser.modal.editFile')"
        type="submit"
        color="primary"
        data-cy="submitModal"
        @click="editFile"
      />
    </template>
  </sq-dialog>
</template>
