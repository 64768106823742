<script>
  import { ref, defineComponent } from 'vue'
  import { useQuasar } from 'quasar'
  import { flowService } from 'src/services'
  import LoadingIndicator from 'components/LoadingIndicator.vue'

  export default defineComponent({
    name: 'FilterSection',
    emits: ['update', 'reset'],
    components: {
      LoadingIndicator
    },
    setup () {
      const $q = useQuasar();
      return {
        expanded: $q.platform.is.mobile ? ref(false) : ref(true),
        selectFlowOptions: ref([]),
      }
    },
    data () {
      return {
        isLoading: true,
        filterObject: [],
        execActive: null,
        execSuccess: false,
        execFail: false,
        dateCreatedFrom: null,
        dateCreatedTo: null,
        preFilterInitialized: false,
        selectedFlows: ref([])
      }
    },
    mounted() {
      this.pageload();
    },
    computed: {
      mappedSelectedFlows() {
        return this.selectedFlows.map(id =>
          this.selectFlowOptions.find(option => option.id === id)
        );
      }
    },
    methods: {
      async pageload() {
        this.isLoading = true;

        /* cs: as spoken with rh on 09.11.2021, we can make getting flows static.
        No environment is supposed to have more than 50 flows in the near future */
        const { data } = await flowService.getFlows(1, 50);

        // load flows from flowService for the select options
        if(data){
          this.flows = data['hydra:member'];
          this.totalFlowItems = data['hydra:totalItems'];

          // Push flow items into the select options array
          if(this.selectFlowOptions.length === 0) { // Only do this on init to ensure all items get pushed
            this.selectFlowOptions = [];
            for (let flow of this.flows) {
              let {id, name} = flow; // Store FE id, flowName and flowId for the select element
              this.selectFlowOptions.push({id: id, label: name}) // q-select accepts `label` and `id`
            }
          }
          if(this.$route.query.flow && !this.preFilterInitialized) this.buildFilterObjectFromState(this.$route.query.flow);
          this.isLoading = false;
        } else {
          // There are no items at all. Nothing to do here.
          // Need to empty the list Array when deleting the last available item though
          this.items = [];
          this.isLoading = false;
        }
      },
      startDateHide() {
        this.$refs.qDateCreatedFromProxy.hide()
        this.buildFilterObjectFromState();
      },
      endDateHide() {
        this.$refs.qDateCreatedToProxy.hide()
        this.buildFilterObjectFromState();
      },
      buildFilterObjectFromState() {
        let filterObject = {
          flowId: [],
          status: {
            active: null,
            success: null,
            fail: null
          },
          dates: {
            createdFrom: '',
            createdTo: '',
            updatedFrom: '',
            updatedTo: ''
          }
        };
        if(this.$route.query.flow && !this.preFilterInitialized) {
          this.selectedFlows = [parseInt(this.$route.query.flow)];
          this.preFilterInitialized = true;
        }

        // get flowIds from select

        this.mappedSelectedFlows.forEach(function(v){filterObject['flowId'].push(v.id)});

        // check status toggles and store the states
        if(this.execActive !== null) { filterObject['status']['active'] = this.execActive ? 1 : 0};
        filterObject['status']['success'] = this.execSuccess ? 1 : 0;
        filterObject['status']['fail'] = this.execFail ? 1 : 0;


        // collect all dates
       filterObject.dates.createdFrom = this.dateCreatedFrom;
       filterObject.dates.createdTo = this.dateCreatedTo;

        this.$emit('update', filterObject);
      },
      resetFilter(full) {
        this.execActive = null;
        this.execSuccess = false;
        this.execFail = false;
        if(full) {
          this.dateCreatedFrom = null;
          this.dateCreatedTo = null;
          this.selectedFlows = [];
        }
        this.buildFilterObjectFromState();
      },

      clearSelectedFlows() {
        this.selectedFlows = [];
        this.buildFilterObjectFromState();
      }
    }
  })
</script>

<template>
  <loading-indicator v-if="isLoading" />

  <div class="full-width flex justify-end q-mb-sm">
    <q-btn
      flat dense
      :label="$t('flow.executions.filter.headline')"
      :title="$t('flow.executions.filter.headline')"
      :icon-right="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
      @click="expanded = !expanded"
      class="app-action-btn q-pa-sm q-ml-sm q-mt-sm"
      :disable="isLoading"
    />
  </div>

  <q-slide-transition>
  <q-card
    flat
    class="app-filter-area q-px-sm q-pt-md"
    v-show="expanded"
    :disabled="isLoading ? true : null">
      <div class="app-filter-area-content">
      <div class="row">

        <div class="col-12 col-md-6 q-my-md q-px-md">
          <q-select
            dense
            flat
            input-debounce="0"
            multiple
            use-chips
            emit-value
            map-options
            class="q-mt-md"
            v-model:model-value="selectedFlows"
            @update:model-value="buildFilterObjectFromState"
            :options="selectFlowOptions"
            option-value="id"
            option-label="label"
            :label="$t('flow.executions.filter.select')"
          >
          <template v-if="selectedFlows.length > 0" v-slot:append>
            <q-icon name="highlight_off" color="negative" @click.stop.prevent="clearSelectedFlows" class="cursor-pointer" />
          </template>
          </q-select>
          </div>

          <div class="col-12 col-md-6 q-my-md q-mt-md-none q-px-md">
          <div class="row items-center">
            <span class="text-body2 q-my-sm">
              {{ $t('flow.executions.filter.stateFilter') }}
            </span>

              <span v-if="(this.execActive !== null) || this.execSuccess || this.execFail" class="q-ml-md">
                <q-btn
                  flat dense
                  color="negative"
                  icon-right="highlight_off"
                  :title="$t('flow.executions.filter.clearStatus')"
                  :label="$t('flow.executions.filter.clearStatus')"
                  @click.capture.stop="resetFilter(false)"
                />
              </span>
            </div>
            <div class="row q-mt-lg q-mt-md-md">
            <div class="col-12 col-sm-auto q-mb-lg q-mb-md-none">
              <q-checkbox
                dense
                class="q-mr-sm-md"
                v-model:model-value="execActive"
                @update:model-value="buildFilterObjectFromState"
                :label="$t('flow.executions.filter.statusActive')"
              />
            </div>
            <div class="col-6 col-sm-auto q-mb-lg q-mb-md-none">
              <q-checkbox
                dense
                v-model:model-value="execSuccess"
                class="q-mr-sm-md"
                :label="$t('flow.executions.filter.statusSuccess')"
                :disable="execActive"
                @update:model-value="buildFilterObjectFromState"
              />
            </div>
            <div class="col-6 col-sm-auto q-mb-lg q-mb-md-none">
              <q-checkbox
                dense
                v-model:model-value="execFail"
                class="q-mr-sm-md"
                :label="$t('flow.executions.filter.statusFail')"
                :disable="execActive"
                @update:model-value="buildFilterObjectFromState"
              />
            </div>
          </div>
          </div>

          <!-- DATE CREATED FROM -->
          <div class="col-12 col-md-6 q-mb-md q-px-md">
          <q-input v-model="dateCreatedFrom" @click="$refs.qDateCreatedFromProxy.show()" readonly dense flat :hint="$t('flow.executions.filter.createdDateFrom')">
            <template v-slot:append>
              <q-btn v-if="dateCreatedFrom !== null" flat icon="highlight_off" color="negative" @click.stop.prevent="this.dateCreatedFrom=null;this.buildFilterObjectFromState()" />

              <q-btn icon="event" flat color="primary">
                <q-popup-proxy ref="qDateCreatedFromProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model:model-value="dateCreatedFrom" @update:model-value="startDateHide" minimal dense flat mask="YYYY-MM-DD HH:mm" />
                </q-popup-proxy>
              </q-btn>
            </template>
          </q-input>
          </div>

          <!-- DATE CREATED TO -->
          <div class="col-12 col-md-6 q-my-md q-mt-md-none q-px-md">
            <q-input v-model="dateCreatedTo" @click="$refs.qDateCreatedToProxy.show()" readonly dense flat :hint="$t('flow.executions.filter.createdDateTo')">
              <template v-slot:append>
                <q-btn v-if="dateCreatedTo !== null" flat icon="highlight_off" color="negative" @click.stop.prevent="this.dateCreatedTo=null;this.buildFilterObjectFromState()" />

                <q-btn icon="event" flat color="primary">
                  <q-popup-proxy ref="qDateCreatedToProxy" cover transition-show="scale" transition-hide="scale">
                  <q-date v-model:model-value="dateCreatedTo" @update:model-value="endDateHide" minimal dense flat mask="YYYY-MM-DD HH:mm" />
                  </q-popup-proxy>
                </q-btn>
              </template>
            </q-input>
          </div>
      </div>

      <div class="row justify-end q-px-md q-mb-md">
        <q-btn
          dense flat
          @click="resetFilter(true)"
          :label="$t('flow.executions.filter.reset')"
          :disable="!(this.execActive || this.execSuccess || this.execFail || this.dateCreatedFrom !== null || this.dateCreatedTo !== null || selectedFlows.length > 0)"
          class="q-mb-md q-mr-sm q-px-sm"
          color="negative"/>
      </div>
    </div>
  </q-card>
  </q-slide-transition>
</template>

<style lang="scss">
  .app-filter-area-content .q-icon.q-chip__icon--remove {
    display: none;
  }
</style>
