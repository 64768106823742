<script>
  import { defineComponent, ref } from 'vue'
  import {date, useQuasar} from 'quasar';
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import DateDisplay from 'components/DateDisplay.vue'
  import {flowExecutionService} from "src/services";
  import FlowExecutionActionBar from "./FlowExecutionActionBar";
  import FlowExecutionBadges from "./FlowExecutionBadges.vue";

  export default defineComponent({
    name: 'FlowExecutionTree',
    props: {
      currentFlow: {
        type: Object,
        required: true
      },
      autoRefresh: {
        required: true
      }
    },
    emits: ['updateFlowDetails', 'finished'],
    components: {
      FlowExecutionBadges,
      FlowExecutionActionBar,
      LoadingIndicator,
      DateDisplay
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q,
        tab: ref('list')
      }
    },
    data () {
      return {
        searchBox: '',
        filter: 'true',
        statusFilter: '',
        expansionStateInitialized: false,
        expandedTreeNodes: ref([]), // init
        expanded: true,
        hideCompleted: ref(false),
        hidePending: ref(false),
        refreshModel: ref(false),
        isLoading: true,
        tree: null,
        oldProgress: null,
        progress: null,
        activityIndicatorLimit: 9999,
        calcHeaderProgressDifferently: true,
        finished: false,
        refreshedOnce: false,
        autoRefreshSeconds: null
      }
    },
    computed: {
      noResultsLabel() {
        const flowSince = new Date(this.currentFlow.updated_at).getTime();
        const now = new Date().getTime();
        const dateDiff = date.getDateDiff(now, flowSince, 'minutes');
        return (dateDiff > 5) ? this.$t('flow.executions.detail.workerNotStarted') : this.$t('flow.executions.detail.workerNotStartedYet');
      }
    },
    methods: {
      getNodeProgressBySum(node, mode, isRecursive = false) {
        let processed = 0;
        let total = 0;

        if (!node.children || isRecursive) {
          let tempTotal = node.totalCount ?? 0;
          processed += node.processedCount;
          total += tempTotal;
        }
        let nullNode = node.children ? true : null;
        for(let childNode of node.children) {
          if(childNode.total !== null) nullNode = false;
          processed += this.getNodeProgressBySum(childNode, 'processed', true);
          total += this.getNodeProgressBySum(childNode, 'total', true);
        }
        let result = parseFloat((processed / total * 100).toFixed(2)).toString();

        if(mode === 'total') { // Calc total items <INT>
          if(isNaN(result)) {
            if(node.totalCount !== null) return node.totalCount;
          } else {
            return total;
          }
        }
        else if(mode === 'processed') { // Calc progressed items <INT>
          if(isNaN(result)) {
            if(node.children.every((cn) => cn.totalCount === null)) return node.children.length;
            return node.processedCount;
          } else {
            return processed;
          }
        } else {
          if(isNaN(result)) { // Calc progress in percent <FLOAT>
            return this.getNodeProgress(node.processedCount, node.totalCount, null, node);
          } else {
            return result
          }
        }
      },
      getNodeProgress(processedCounts, totalCounts, done) {
        if(typeof processedCounts === "undefined" || processedCounts == null) return null;
        if(typeof totalCounts === "undefined" || totalCounts == null) return null;
        if(totalCounts === 0) return "100";
        let result = parseFloat((processedCounts / totalCounts) * 100).toFixed(2).toString();
        if(result.endsWith(".00")) {
          result = result.slice(0, -3);
        }

        // If progress should show 100% for nullNodes, activate this, but don't forget to attach the child prop to function (passed in updateTree function)
        /*console.log(children)
        if(children && children.length > 0) {
          let nullNode = true;
          for(let childNode of children) {
            if(childNode.totalCount !== null) nullNode = false;
          }
          if(nullNode) return "100";
        }*/

        return result;
      },
      getNodeItems(processedCounts, totalCounts) { // Handle fallback in case of missing variables
        let pC = processedCounts;
        let tC = totalCounts;
        if(typeof pC === "undefined" || pC == null) pC = '-';
        if(typeof tC === "undefined" || tC == null) tC = '-';
        return pC.toString() + ' / ' + tC.toString();
      },
      getBarProgress(node) {
        if(node.done) return 100;
        if (this.calcHeaderProgressDifferently) {
          if (this.getNodeProgressBySum(node) === null) return 0;
          return this.getNodeProgressBySum(node);
        } else {
          if (this.getNodeProgress(node.processedCount, node.totalCount) === null) return 0;
          return this.getNodeProgress(node.processedCount, node.totalCount);
        }
      },
      refreshArray(tree) {
        let expandedTreeNodes = [];
        let treeExpandIteration = function(node) {
          node?.forEach(branch => {
            /* uniqueLabel -> not part of API. Generated in Store mutation. Equals: branch.id + '-' + branch.processStepSubsection */
            expandedTreeNodes.push(branch.uniqueLabel);
            if(typeof branch.children !== "undefined") {
              branch.children.forEach(child => {
                expandedTreeNodes.push(child.uniqueLabel);
                // Fixed to two levels for now
              })
            }
          });
        };
        treeExpandIteration(tree);
        this.expandedTreeNodes = ref(expandedTreeNodes);
        this.expansionStateInitialized = true;
      },
      refreshTree() {
        this.isLoading = true;
        flowExecutionService.getFlowExecutionDetails(this.$route.params.flowid, (data) => {
          this.isLoading = false;
          this.updateTree(data);
        })
      },
      updateFilter(filter) {
        this.searchBox = this.filter = filter;
      },
      hidePendingFctn() {
        this.hidePending = !this.hidePending;
        this.statusFilter = this.hidePending;
      },
      hideCompletedFctn() {
        this.hideCompleted = !this.hideCompleted;
        this.statusFilter = this.hideCompleted;
      },
      expandOrCollapseAll() {
        if(this.expanded) {
          this.expandedTreeNodes = [];
        } else {
          let expandedTreeNodes = [];
          let treeExpandIteration = function(node) {
            node.forEach(branch => {
              /* uniqueLabel -> not part of API. Generated in Store mutation. Equals: branch.id + '-' + branch.processStepSubsection */
              expandedTreeNodes.push(branch.uniqueLabel);
              branch.children.forEach(child => {
                expandedTreeNodes.push(child.uniqueLabel);
                if(child.children) treeExpandIteration(child.children); //recursion for all further child levels
              })
            });
          };
          treeExpandIteration(this.tree);
          this.expandedTreeNodes = ref(expandedTreeNodes)
        }
        this.expanded = !this.expanded;
      },
      updateTree(data) {
        if(this.autoRefresh) this.refreshedOnce = true; // This variable ensures finished animation only played when there wasn't already everything complete on init
        if(!data?.active) {
          this.$emit('finished')
          this.finished = true;
        }
        let treeArray = data.flowExecutionProgressInformation;
        this.oldProgress = this.progress;
        this.progress = treeArray;
        const updated = JSON.stringify(treeArray) !== JSON.stringify(this.oldProgress);
        let listToTree = (list) => {
          var map = {}, node, roots = [], i;

          for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // init
            list[i].children = []; // init
          }

          for (i = 0; i < list.length; i += 1) {
            node = list[i];
            node.uniqueLabel = node['id'] + '-' + node['processStepSubsection']; // required for expansion array

            node.wasUpdated = false;
            if(updated) {
              if(this.oldProgress !== null) {
                if(this.calcHeaderProgressDifferently) {
                  if (parseFloat(this.getNodeProgressBySum(list[i])) !== parseFloat(this.getNodeProgressBySum(this.oldProgress[i]))) {
                    node.wasUpdated = true;
                  }
                }
                else {
                  if (
                    this.getNodeProgress(list[i].processedCount, list[i].totalCount)
                    >
                    this.getNodeProgress(this.oldProgress[i].processedCount, this.oldProgress[i].totalCount)
                  ) {
                    node.wasUpdated = true;
                  }
                }
              }
            }

            if (node.parentId !== null && node.parentId !== 0) {
              // if there are dangling branches, check that map[node.parentId] exists
              const branch = list[map[node.parentId]];
              if (typeof branch !== "undefined" && typeof branch.children !== "undefined") branch.children.push(node);
            } else {
              roots.push(node);
            }
          }
          return roots;
        };
        this.tree = listToTree(treeArray);
        this.$emit('updateFlowDetails', data)

        this.isLoading = false;
        if(this.expansionStateInitialized === false) this.refreshArray(this.tree)
      },
      treeFilter (node, filter) {
        // The filter param is ignored in here.

        // Instead, we use the contents of the search box input ...
        const searchBoxText = this.searchBox.toLowerCase()

        const matchedNodeLabel = (node.processStepSubsection && node.processStepSubsection.toLowerCase().indexOf(searchBoxText) > -1)

        // And any status button that was clicked ....
        let matchedStatus = true;

        if (this.statusFilter !== '') {
          if(this.hidePending) {
            matchedStatus = !(
                (
                    (parseInt(this.getNodeProgress(node.processedCount, node.totalCount)) < 100) &&
                    (parseInt(this.getNodeProgress(node.processedCount, node.totalCount)) > 0)
                )
                &&
                node.processedCount !== node.totalCount
                &&
                this.getNodeItems(node.processedCount, node.totalCount) !== null
            );
          }
          if(this.hideCompleted) {
            matchedStatus = matchedStatus && !(parseInt(this.getNodeProgress(node.processedCount, node.totalCount)) === 100);
          }
        }

        return (matchedNodeLabel && matchedStatus)
      },
      toggleProgressDisplay(val) {
        this.calcHeaderProgressDifferently = val;
      },
      updateAutorefreshSeconds(val) {
        this.autoRefreshSeconds = val;
      }
    },
    watch: {
      // Whenever the searchBox property changes,
      // use it to trigger the tree filter's reactivity
      // by assigning its value to the
      // filter property.
      // Actually, the value we assign here is irrelevant.
      searchBox: function (newVal, oldVal) {
        this.filter = newVal;
      }
    },
    created() {
      this.updateTree(this.$props.currentFlow);
    }
  })
</script>

<template>
  <loading-indicator v-if="isLoading && !(tree?.length > 0)" wrapper />
  <template v-if="(tree?.length > 0)">
    <flow-execution-action-bar v-if="tree" :tree-length="tree.length"
                               @update-filter="updateFilter"
                               @update-pending="hidePendingFctn"
                               @update-completed="hideCompletedFctn"
                               @refresh="refreshTree"
                               @expand="expandOrCollapseAll"
                               @update-progress-display="toggleProgressDisplay"
                               @autorefresh-seconds="updateAutorefreshSeconds"
                               :calc-header-progress-differently="calcHeaderProgressDifferently"
                               :finished="finished"
    />

    <q-tree v-if="tree"
            ref="projectTree"
            :nodes="tree"
            @load="expansionStateInitialized === false ? refreshArray(tree) : null"
            node-key="uniqueLabel"
            label-key="processStepSubsection"
            :filter="filter"
            :filter-method="treeFilter"
            class="flex justify-content-center column q-my-lg"
            :class="{
              'disabled': isLoading,
              'hide-pending': this.hidePending === true,
              'hide-completed': this.hideCompleted === true,
              'app-tree-finished': finished && refreshedOnce
            }"
            default-expand-all
            v-model:expanded="expandedTreeNodes"
            :no-results-label="$t('flow.executions.detail.noResults')"
    >
      <template v-slot:default-header="prop">
        <div class="app-flowexecution-top-label-wrapper"
             :class="{
              'app-recently-updated-node':
              (prop.node.wasUpdated && this.autoRefresh) &&
              this.autoRefreshSeconds > 0 &&
              (!prop.node.finished && (prop.node.processedCount !== prop.node.totalCount))
             }"
             :title="prop.node.processStepSubsection"
        >
          {{ prop.node.label ?? prop.node.processStepSubsection }}

          <q-tooltip
                  anchor="top middle" self="bottom middle"
                  v-if='$q.platform.is.mobile'
                  class="app-tooltip-mobile fixed-tooltip"
          >
            {{ prop.node.processStepSubsection }}

            <flow-execution-badges
                :prop="prop"
                :get-node-progress="getNodeProgress"
                :get-node-progress-by-sum="getNodeProgressBySum"
                :get-node-items="getNodeItems"
                :calc-header-progress-differently="calcHeaderProgressDifferently"
            />
          </q-tooltip>

          <flow-execution-badges
              :prop="prop"
              :get-node-progress="getNodeProgress"
              :get-node-progress-by-sum="getNodeProgressBySum"
              :get-node-items="getNodeItems"
              :calc-header-progress-differently="calcHeaderProgressDifferently"
          />

        </div>
        <div class="app-flowexecution-wrapper flex justify-center full-width"
             :class="{
         'q-mt-md': !prop.node.parentId
         }"
             style="height: .25rem;" :style="{
           backgroundImage: 'linear-gradient(90deg, #2B00B0, #9B60ff' + ' ' + getBarProgress(prop.node) + '%, transparent 0)'
         }"
        >
        </div>
      </template>
    </q-tree>
    <div class="app-all-filtered-info hidden">
      {{ $t('flow.executions.detail.allFiltered') }}
    </div>
  </template>

  <div class="text-center" v-if="!isLoading && !(tree?.length > 0)">
    <q-icon size="3rem" name="warning" color="negative" />
    <p class="q-mt-md q-px-sm q-px-md-xl">{{ noResultsLabel }}</p>
    <q-btn
      flat dense
      class="app-action-btn q-px-sm"
      :label="$t('flow.executions.detail.refresh')"
      @click="refreshTree"
    />
  </div>
</template>

<style lang="scss">
  .q-tree {
    margin-top: 1rem;
    &.app-tree-finished {
      animation: finish-animation 1s ease-in-out;
      @keyframes finish-animation {
        0% {
          /* Define the initial state */
          background: transparentize($positive, .75);
        }
        100% {
          /* Define the final state */
          background: unset;
        }
      }
    }
    .q-tree__arrow {
      margin: 0 2px 4px -4px
    }
    .q-tree__node {
      .app-recently-updated-node {
        .app-progress-badge {
          animation: pulse 2s infinite;
        }
      }
      .q-tree__node--parent, .q-tree__node--child {
        padding-top: .25rem;
        &:first-of-type {
          padding-top: .75rem;
        }
        .q-tree__node-header {
          margin: unset;
          padding: 0.25rem 0;
          &:hover {
            background-color: darken($background2, 10%);
          }
        }
      }
      .app-flowexecution-top-label-wrapper {
        max-width: 100%;
        white-space: nowrap;
      }
      .q-tree__node-header-content {
        .app-flowexecution-top-label-wrapper {
          position: absolute;
          top: -.75rem;
        }
        .app-flowececution-wrapper {
          background-color: darken($background2, 10%);
          background-repeat: no-repeat;
        }
      }
    }
  }
  .q-tree > .q-tree__node > .q-tree__node-header .app-flowexecution-top-label-wrapper {
    top: 0;
  }
  .q-tree .q-tree__node .q-tree__node-header-content {
    margin-top: .25rem;
  }
  .q-tree__node {
    max-width: 1080px;
  }
  .q-tree__node {
    .q-tree__arrow {
      margin: 0 0 6px 0;
    }
    &:last-child {
      .q-tree__node-header::before {
        bottom: .75rem;
        @media (min-width: $breakpoint-xs) {
          top: -1.5rem;
        }
      }
    }
  }
  .q-tree__children {
    width: 100%;
    padding-left: 8px;
    @media (min-width: $breakpoint-xs) {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
      min-width: 480px; /* Might require extra adjustment for deep levels */
    }
  }
  .q-tree .q-badge {
    margin: .25rem;
    padding: .25rem;
    max-height: 1rem;
    font-weight: 600;
    @media (min-width: $breakpoint-xs) {
      margin: unset;
    }
  }
  .app-flow-execution-refresh-btn {
    button:first-of-type {
      padding-right: .5rem;
    }
    .q-btn__content {
      font-weight: 600;
    }
    &.loading button:first-of-type i {
      animation: running-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px transparentize($primary, .2);
    }
    100% {
      box-shadow: 0 0 0 10px transparentize($primary, 1);
    }
  }
  @keyframes pulse-dark {
    0% {
      box-shadow: 0 0 0 0px transparentize($secondary, .2);
    }
    100% {
      box-shadow: 0 0 0 10px transparentize($secondary, 1);
    }
  }
  @keyframes running-animation {
    0% {
      transform: scale(.8) rotate(0deg);
    }
    25% {
      transform: scale(.8) rotate(90deg);
    }
    50% {
      transform: scale(.8) rotate(180deg);
    }
    75% {
      transform: scale(.8) rotate(270deg);
    }
    100% {
      transform: scale(.8) rotate(360deg);
    }
  }
  .app-flex-execution-detail-tabs .q-tab {
    max-width: 552px;
  }

  .app-all-filtered-info {
    color: $gray;
  }

  .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
    background-color: $background2;
    border-radius: 4px;
  }

  .app-tree-action-bar .q-icon {
    display: inline-block;
  }

  .app-flowexecution-wrapper {
    background-color: darken($background2, 10%);
  }

  .fixed-tooltip {
    // Overwrite quasar default behaviour of tooltips
    position: fixed;
    top: unset !important;
    bottom: 0;
    left: 0 !important;
    width: 100%;
  }

  body.body--dark {
    .q-tree {
      .q-tree__node {
        .app-recently-updated-node {
          .app-progress-badge {
            animation: pulse-dark 2s infinite;
          }
        }
        .q-tree__node--parent, .q-tree__node--child {
          .q-tree__node-header {
            &:hover {
              background-color: $dark-page;
            }
          }
        }
      }
    }
    .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
      background-color: $dark;
    }
    .app-flowexecution-wrapper {
      background-color: lighten($dark, 10%);
    }
  }
</style>
