import { date } from 'quasar'
import { i18n } from 'src/boot/i18n';

export function receivedData(state, data) {
  state.supervisor = data;
  let workerColumns;
  let workerRows = [];

  workerColumns = [
    {
      name: 'name', // must be 'name' - quasar default
      required: true,
      label: i18n.global.t('dashboard.supervisor.table.name'),
      align: 'left',
      field: row => row.group,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'uptime',
      required: true,
      label: i18n.global.t('dashboard.supervisor.table.uptime'),
      align: 'left',
      field: row => row.uptime,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'status',
      required: true,
      label: i18n.global.t('dashboard.supervisor.table.status'),
      align: 'left',
      field: row => row.status,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'action',
      required: true,
      label: i18n.global.t('dashboard.supervisor.table.action'),
      align: 'left',
      sortable: false
    }
  ];
  state.workerColumns = workerColumns;

  if(typeof data !== "undefined") {
    let workers = [];
    data.forEach((worker, index) => {
      if (!workers.includes(worker.group)) {
        workerRows.push({
          rowId: index + 1,
          name: worker.identifier,
          uptime: worker.description,
          status: worker.status,
          group: worker.group
        })
        workers.push(worker.group)
      }
    });
    state.workerRows = workerRows;
    freeComponent(state)
  }
}

export function startLoading(state) {
  //state.supervisor = null;
  state.isLoading = true;
}

export function startReloading(state) {
  state.isRefreshing = true;
}

export function freeComponent(state) {
  state.isRefreshing = false;
  state.isLoading = false;
}
