<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Pagination',

    props: {
      pages: {
        type: Number,
        required: true
      },

      modelValue: {
        type: Number,
        default: 1
      },
    },

    emits: ['update:modelValue'],

    data () {
      return {
        localValue: this.modelValue
      }
    },

    computed: {
      requestedPage: {
        get() {
          return this.localValue;
        },

        set(val) {
          this.localValue = val;
          this.$emit('update:modelValue', val);
        }
      }
    },

    watch: {
      modelValue(newValue) {
        this.localValue = newValue
      }
    }
  })
</script>

<template>
  <q-pagination
    v-model:model-value="requestedPage"
    :max="pages"
    input
  />
</template>

<style lang="scss">
  .q-pagination input.q-field__native {
    &:hover, &:focus {
      border: 1px solid $secondary;
    }
  }
</style>
