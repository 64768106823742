<script>
import { defineComponent } from 'vue'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'saveQueryModal',
  components: {
    SqDialog
  },
  props: {
    name: {
      required: true,
      type: String
    }
  },
  emits: ['confirm'],
  methods: {
    confirmDelete() {
      this.$emit('confirm', this.$props.name);
    }
  },
})
</script>

<template>
  <sq-dialog
    type="create"
    @save="confirmDelete"
    :save-button-label="$t('general.delete')"
  >
    <template #title>
      {{ $t('entities.modal.confirmDeleteQueryTitle') }}
    </template>

    <template #content>
      {{ $t('entities.modal.confirmDeleteQueryLabel', {query: this.$props.name}) }}

    </template>

    <template #saveButton>
      <q-btn flat
             type="submit"
             color="negative"
             :label="this.$t('general.delete')"
             @click="confirmDelete"
      />
    </template>
  </sq-dialog>
</template>
