<script>
  import { defineComponent } from 'vue'
  import { mapState } from 'vuex'
  import EntityListingRow from './EntityListingRow.vue'
  import Paginator from "components/Paginator.vue";

  export default defineComponent({
    name: 'EntityListing',

    components: {
      Paginator,
      EntityListingRow
    },

    props: {
      entityData: {
        required: true,
        type: Object
      },

      initialPage: {
        required: true,
        type: Number
      },

      hasFilter: {
        required: true,
        type: Boolean,
        default: false
      },

      totalItems: {
        required: true,
        type: Number
      },

      parentWidth: {
        required: true,
        type: String
      }
    },

    emits: ['edited', 'page-change'],

    data () {
      return {
        page: this.initialPage,
        itemsPerPage: 30,
        editedRowArray: [],
        requestedPage: this.initialPage
      }
    },

    computed: {
      ...mapState('entities', ['currentEntityName']),

      entityList() {
        return this.$router.currentRoute._value.params.entitylist;
      },

      totalPages() {
        const totalItems = this.entityData['hydra:totalItems'] || 0;

        return Math.ceil(totalItems / this.itemsPerPage)
      }
    },

    methods: {
      pageChange() {
        this.$emit('page-change', this.requestedPage)
      },

      editHandler(index, data) {
        // This needs to be an array because we need ti check the edit state of all rows
        // If only one returns 'true', this is already enough to enable the warning modal
        if(data === true) this.editedRowArray[index] = data;
        else this.editedRowArray.splice(index, 1);
        this.$emit('edited', this.editedRowArray.length > 0)
      },

      triggerQueryByHotkey(event) {
        if ((event.key === 's' || event.key === 'Enter') && (event.ctrlKey || event.metaKey)) {
          event.preventDefault();
        }
      }
    },

    watch: {
      initialPage() {
        this.requestedPage = this.initialPage
      }
    }
  })
</script>

<template>
  <div class="flex column relative-position">
    <q-separator />
    <div class="q-mb-sm">
      <div v-if="hasFilter">

        <div class="flex items-center q-my-md">
          <q-icon
            name="filter_alt"
            color="primary" />
          <span class="q-ml-sm">{{ $t('entities.filterActiveNotice') }}</span>
        </div>

        <q-separator />
      </div>

      <div class="row q-ma-sm q-my-sm">
        <div class="col-4 col-lg-3 q-px-sm"><span>{{ $t('entities.id') }}</span></div>
        <div class="col-4 col-lg-3 q-px-sm"><span>{{ $t('entities.mongoId') }}</span></div>
        <div class="col-4 col-lg-3 q-px-sm"><span>{{ $t('entities.date') }}</span></div>
      </div>

      <entity-listing-row
        v-for="(row, index) in entityData['hydra:member']"
        :key="row.uuid"
        :row="row"
        :total-items="$props.totalItems"
        :parent-width="$props.parentWidth"
        @edited="editHandler(index, $event)"
      />

      <p
        v-if="!entityData['hydra:member']?.length"
        class="text-center text-gray q-mt-lg">
        {{ $t('entities.filterNoEntityRows') }}
      </p>
    </div>

  </div>

  <div
    v-if="totalPages > 1 && requestedPage"
    class="q-pb-md full-width"
  >
    <div class="q-pb-lg flex flex-center">
      <paginator
        v-model:page="requestedPage"
        :total-pages="totalPages"
        class="q-mt-lg"
        @update:page="pageChange"
      />
    </div>
  </div>
</template>

<style lang="scss">
  body.body--dark {
    .app-entity-query-editor-wrapper {
      background-color: $dark;
      &:hover {
        background-color: mix($dark,$secondary,95%);
      }
      .q-btn.text-negative {
        background-color: $negative;
        color: white !important;

        &:hover {
          background-color: mix($negative,$secondary,80%);
        }
      }
    }
  }
</style>
