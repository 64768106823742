<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'
  import {useQuasar} from "quasar";
  import DateDisplay from "components/DateDisplay.vue";

  export default defineComponent({
    name: "Heartbeat",
    components: {
      DateDisplay,
      LoadingIndicator
    },
    props: {
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q
      }
    },
    computed: {
      ...mapGetters('heartbeats', [
        'isLoading',
        'isRefreshing',
        'heartbeat'
      ]),
      lastHeartbeat() {
        return this.heartbeat ? this.heartbeat[this.heartbeat.length - 1] : 0;
      },
      exceeding() {
        // Delay is below INTERVAL
        if (this.lastHeartbeat.heartbeat_delay <= this.lastHeartbeat.heartbeat_interval) return -1;
        // Delay is below INTERVAL + GRACE_PERIOD
        if (this.lastHeartbeat.heartbeat_delay < (this.lastHeartbeat.heartbeat_interval + this.lastHeartbeat.heartbeat_grace)) return 0;
        // Delay exceeds INTERVAL + GRACE_PERIOD
        if (this.lastHeartbeat.heartbeat_delay > (this.lastHeartbeat.heartbeat_grace + this.lastHeartbeat.heartbeat_grace)) return 1;
      }
    },
    mounted () {
      this.$store.dispatch('heartbeats/getHeartbeat')
    },
    methods: {
      refreshHeartbeat() {
        this.$store.dispatch('heartbeats/getHeartbeat');
      },
      startHeartbeat() {
        this.$store.dispatch('heartbeats/startHeartbeat', { label: this.$t('dashboard.heartbeat.refresh'), action: () => {this.refreshHeartbeat()} } );
      }
    }
  });
</script>
<template>
  <div class="flex justify-between relative-position" :class="col">
    <loading-indicator v-if="isLoading" wrapper />
    <q-card v-else class="app-heartbeat full-width row" :class="col, {
                'app-heartbeat-positive': exceeding === -1,
                'app-heartbeat-warning': exceeding  === 0,
                'app-heartbeat-negative': exceeding === 1,
                'app-heartbeat-none': lastHeartbeat === 0,
                }" flat :disabled="isRefreshing ? true : null">
      <q-card-section class="col" vertical>
        <q-card-section class="flex justify-between app-heartbeat-row-top" horizontal>
          <div class="app-heartbeat-headline-container flex">
          <h2 class="app-heartbeat-headline q-pr-sm q-pl-xs">{{ $t('dashboard.heartbeat.headline') }}</h2>
          <q-btn flat dense icon-right="autorenew" size="9px" class="justify-end"
                 :label="$q.platform.is.mobile ? $t('dashboard.heartbeat.refresh') : null"
                 :title="$t('dashboard.heartbeat.refresh')"
                 @click.capture.stop="refreshHeartbeat()"
                 :disabled="isRefreshing"
                 data-cy="heartbeatRefreshButton"
          />
          </div>
        </q-card-section>
        <q-card-section class="flex justify-between app-heartbeat-row-bottom" horizontal>
          <q-card-section class="app-heartbeat-value row justify-between items-center full-width" data-cy="heartbeatValue">
            <div class="col flex inline items-center justify-start">
              <div class="q-mr-sm">
                <q-tooltip class="bg-negative text-white text-body2" v-if="exceeding > 0">
                  {{ $t('dashboard.heartbeat.graceExceededInfo') }}
                </q-tooltip>
                <div class="app-heartbeat-delay-info flex-sm items-center" v-if="!isLoading">
                  <q-icon class="q-mr-sm" name="img:assets/icons/heartbeat.svg" />
                  <span v-if="lastHeartbeat !== 0">
                    {{ $t('dashboard.heartbeat.delay') }}
                    <date-display class="text-weight-bold"
                        :start-time="this.heartbeat[this.heartbeat.length-1].heartbeat_current"
                        :end-time="this.heartbeat[this.heartbeat.length-1].timestamp_current"
                        :offset="true"
                    />
                    {{ $t('dashboard.heartbeat.delay2') }}
                  </span>
                  <span v-if="lastHeartbeat === 0">{{ $t('dashboard.heartbeat.noHeartbeat') }}</span>
                  <q-icon v-if="exceeding > 0" class="inline q-pl-sm text-negative" name="warning" size="1.25rem" />
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card-section>
      </q-card-section>
      <q-card-section class="col-auto flex justify-center" vertical>
        <div class="col flex inline items-center justify-end">
          <q-btn v-if="exceeding >= 0 || lastHeartbeat === 0"
                 flat dense icon-right="restart_alt"
                 class="q-mr-sm q-px-sm justify-end text-danger"
                 :class="{
                    'text-warning': exceeding === 0,
                    'text-negative': exceeding === 1}"
                 :label="$t('dashboard.heartbeat.start')"
                 @click.capture.stop="startHeartbeat()"
                 :disabled="isRefreshing"
                 data-cy="heartbeatStartButton"
          />
          <div v-if="lastHeartbeat !== 0" class="q-mr-md app-text-weight-semibold" :class="col, {
          'text-positive': exceeding === -1,
          'text-warning': exceeding  === 0,
          'text-negative': exceeding === 1 }">
            <q-icon size="sm" :name="exceeding < 0 ? 'check_circle_outline' : 'error_outline'" class="q-mr-sm" />
            <span v-if="exceeding === -1">{{ $t('dashboard.heartbeat.fine') }}</span>
            <span v-if="exceeding === 0">{{ $t('dashboard.heartbeat.warning') }}</span>
            <span v-if="exceeding === 1">{{ $t('dashboard.heartbeat.error') }}</span>
          </div>

        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss">
  .app-heartbeat-headline-container {
    .app-heartbeat-headline {
      line-height: 1;
      font-size: 1rem;
    }
  }

  @mixin heartbeat-color($color) {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="' + $color + '" width="76.044" height="43.415" id="Schnittmenge_4" data-name="Schnittmenge 4" viewBox="0 0 76.044 43.415"%3E%3Cpath d="M10.179,43.407a1.781,1.781,0,0,1-1.687-1.267L2.757,20.931,1.1,25.522A1.8,1.8,0,0,1-.615,26.667H-11.479a1.747,1.747,0,0,1-1.811-1.674,1.747,1.747,0,0,1,1.811-1.674H-1.92l3.209-8.9a1.783,1.783,0,0,1,1.786-1.143,1.781,1.781,0,0,1,1.686,1.267L10.5,35.751,23.016,1.024a1.95,1.95,0,0,1,3.434,0L38.968,35.751,44.7,14.542a1.782,1.782,0,0,1,1.687-1.267,1.741,1.741,0,0,1,1.786,1.143l3.209,8.9h9.559a1.884,1.884,0,0,1,1.28.49,1.587,1.587,0,0,1,0,2.368,1.887,1.887,0,0,1-1.28.49H50.081a1.8,1.8,0,0,1-1.717-1.145l-1.655-4.59L40.973,42.14a1.781,1.781,0,0,1-1.687,1.267l-.069,0A1.8,1.8,0,0,1,37.5,42.264L24.733,6.848,11.965,42.264a1.667,1.667,0,0,1-1.623,1.15Q10.261,43.414,10.179,43.407Z" transform="translate(13.289 0.001)" opacity=".10" /%3E%3C/svg%3E');
  }

  @function encode-color($string) {
    @if type-of($string) == 'color' {
      $hex: str-slice(ie-hex-str($string), 4);
      $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
    @return $string;
  }

  .app-heartbeat {
    &.app-heartbeat-positive {
      background-color: $positive-bg;
      @include heartbeat-color(encode-color($positive));
    }
    &.app-heartbeat-warning {
      background-color: $warning-bg;
      @include heartbeat-color(encode-color($warning));
    }
    &.app-heartbeat-negative {
      background-color: $negative-bg;
      @include heartbeat-color(encode-color($negative));
    }
    &.app-heartbeat-none {
      .app-heartbeat-delay-info {
        color: $dark;
      }
    }
    background-repeat: no-repeat no-repeat;
    background-position: left top;
    background-size: contain;
    .app-heartbeat-row-top {
      padding-bottom: 0;
    }
    .app-heartbeat-row-bottom {
      padding-top: 0;
    }
  }

  body.body--dark {
    .app-heartbeat {
      &.app-heartbeat-positive {
        background-color: $positive-bg-dark;
      }
      &.app-heartbeat-warning {
        background-color: $warning-bg-dark;
      }
      &.app-heartbeat-negative {
        background-color: $negative-bg-dark;
      }
      &.app-heartbeat-none {
        .app-heartbeat-delay-info {
          color: $light;
          .q-icon:first-child {
            filter: invert(1);
          }
        }
      }
      .app-heartbeat-delay-info {
        .q-icon:first-child {
          filter: invert(1);
        }
      }
    }
  }
</style>
