<script>
  import { defineComponent, ref } from 'vue'
  import { supervisorService } from 'src/services'
  import { useQuasar } from 'quasar'
  import LoadingIndicator from 'components/LoadingIndicator'
  import RestartWorkerModal from './modal/RestartWorkerModal'
  import DateDisplay from 'components/DateDisplay'
  import {i18n} from "boot/i18n";

  export default defineComponent({
    name: 'Supervisor',
    components: {
      LoadingIndicator,
      DateDisplay
    },
    props: {
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup () {
      const $q = useQuasar();
      const workerColumns = [
        {
          name: 'name',
          required: true,
          label: i18n.global.t('dashboard.supervisor.table.name'),
          align: 'left',
          style: 'width: 30%',
          field: row => row.group ?? row.identifier,
          format: val => `${val}`,
          sortable: false
        },
        {
          name: 'uptime',
          required: true,
          label: i18n.global.t('dashboard.supervisor.table.uptime'),
          align: 'left',
          style: 'width: 30%',
          sortable: false
        },
        {
          name: 'status',
          required: true,
          label: i18n.global.t('dashboard.supervisor.table.status'),
          align: 'left',
          style: 'width: 30%',
          sortable: false
        },
        {
          name: 'action',
          required: true,
          label: i18n.global.t('dashboard.supervisor.table.action'),
          align: 'left',
          style: 'width: 10%',
          sortable: false
        }
      ];

      return {
        pagination: {
          rowsPerPage: 0
        },
        workerColumns,
        qInstance: $q,
      }
    },

    data() {
      return {
        loading: false,
        workerRows: [],
        workerProcesses: {},
        errorRetries: 0
      }
    },

    created() {
      this.getSupervisorData()
    },

    methods: {

      restartGroup(groupName) {
        this.loading = true;

        supervisorService.restartGroup(groupName)
          .then(res => {
            if (res.data) {
              if (res.data.success) {
                for (const result of res.data.data) {
                  const message = this.$t('dashboard.supervisor.restartGroupSuccess', { identifier: result.name });
                  setTimeout(() => {
                    this.$store.dispatch('alert/success', message + '',{ root: true });
                  }, 10);
                }
                return;
              }
              this.$store.dispatch('alert/error', 'dashboard.supervisor.restartFailed',{ root: true });
            }
          })
          .catch(err => {
            if(err.toString().includes("code 403")) {
              this.$store.dispatch('alert/error', 'dashboard.supervisor.accessDenied', { root: true });
            }
            console.error(err)
          })
          .finally(_ => {
            this.getSupervisorData();
          })
      },

      stopGroup(groupName) {
        this.loading = true;

        supervisorService.stopGroup(groupName)
          .then(res => {
            if (res.data) {
              if (res.data.success) {
                for (const result of res.data.data) {
                  const message = this.$t('dashboard.supervisor.stopGroupSuccess', { identifier: result.name });
                  setTimeout(() => {
                    this.$store.dispatch('alert/success', message + '',{ root: true });
                  }, 10);
                }
                return;
              }
              this.$store.dispatch('alert/error', 'dashboard.supervisor.stopFailed',{ root: true });
            }
          })
          .catch(err => {
            if(err.toString().includes("code 403")) {
              this.$store.dispatch('alert/error', 'dashboard.supervisor.accessDenied', { root: true });
            }
            console.error(err)
          })
          .finally(_ => {
            this.getSupervisorData();
          })
      },

      getSupervisorData(fromRestartAll = false) {
        this.loading = true;
        this.workerRows = [];

        supervisorService.getSupervisor()
          .then(res => {
            if (res.data) {
              const data = res.data['hydra:member'];
              let workers = [];
              let index = 0;
              for (const worker of data) {
                if (worker.status === 'STARTING') {
                  this.getSupervisorData()
                  break;
                }

                if (!workers.includes(worker.group)) {
                  this.workerRows.push({
                    rowId: ++index,
                    name: worker.identifier,
                    status: worker.status,
                    group: worker.group
                  })

                  workers.push(worker.group);
                  this.workerProcesses[worker.group] = [
                    {
                      identifier: worker.identifier,
                      startTime: worker.startTime,
                      stopTime: worker.stopTime,
                      description: worker.description,
                      status: worker.status
                    }
                  ];
                  continue;
                }

                this.workerProcesses[worker.group].push({
                  identifier: worker.identifier,
                  startTime: worker.startTime,
                  stopTime: worker.stopTime,
                  description: worker.description,
                  status: worker.status
                });
              }
              this.errorRetries = 0;

              if (fromRestartAll) {
                this.$store.dispatch('alert/success', 'dashboard.supervisor.restartSuccess', { root: true });
              }

              this.loading = false;
            }
          })
          .catch(err => {
            if (this.errorRetries < 3) {
              setTimeout(() => {
                this.getSupervisorData(fromRestartAll);
                this.errorRetries++;
              }, fromRestartAll ? 5000 : 0);
              return;
            }
            console.error(err);
            this.loading = false;
          })
      },

      openRestartWorkerModal() {
        this.qInstance.dialog({
          component: RestartWorkerModal,
          componentProps: {
            supervisor: this.supervisor
          },
        }).onOk(() => {
          this.getSupervisorData(true)
        })
      },
    }
  });
</script>
<template>
  <div class="flex justify-between q-pt-md relative-position" :class="col">
    <q-card class="app-supervisor full-width"  flat :disabled="loading ? true : null">
      <loading-indicator v-if="loading" />
      <q-icon size="3rem" name="assessment" class="app-card-bg-icon"></q-icon>
      <q-item>
        <q-item-section>
          <q-item-label class="app-supervisor-label app-text-weight-semibold">{{ $t('dashboard.supervisor.headline') }}</q-item-label>
        </q-item-section>
      </q-item>
      <template v-if="!loading && workerRows">
        <q-card-section class="q-py-none" horizontal>
          <q-card-section class="app-supvervisor-content col q-py-none q-ml-sm" data-cy="supervisorContent">
            <div class="flex full-width">
              <div class="q-mr-xl full-width">
                <q-table dense flat
                        table-style="border-color: blue"
                        :rows="workerRows"
                        :columns="workerColumns"
                        :row-key="workerRows.rowId"
                        :pagination.sync="pagination"
                        class="full-width supervisor-table"
                        hide-bottom
                >
                  <template v-slot:body="props">
                    <q-tr :props="props" class="border-danger cursor-pointer" @click="props.row.expand = !props.row.expand">
                      <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <template v-if="col.name !== 'action'">
                          {{ col.value }}
                        </template>

                        <template v-if="col.name === 'status' && !props.row.expand">
                          <q-icon class="q-pb-xs"
                                  v-for="worker in workerProcesses[props.row.group]"
                                  :color="worker.status === 'RUNNING' || worker.status === 'STARTING' ? 'positive' : 'negative'"
                                  :name="worker.status === 'RUNNING' || worker.status === 'STARTING' ? 'check_circle' : 'error'"
                          />
                        </template>

                        <template v-if="col.name === 'action'">
                          <q-btn v-if="props.row.status !== 'RUNNING'" flat dense class="app-action-btn"
                                 :label="$t('dashboard.supervisor.restart')"
                                 @click.capture.stop="restartGroup(props.row.group)"
                          />

                          <q-btn v-if="props.row.status === 'RUNNING' && props.row.group !== 'webserver'" flat dense class="app-action-btn"
                                 :label="$t('dashboard.supervisor.stop')"
                                 @click.capture.stop="stopGroup(props.row.group)"
                          />
                        </template>
                      </q-td>
                    </q-tr>

                    <q-tr v-for="worker in workerProcesses[props.row.group]" v-show="props.row.expand" :props="props">
                      <q-td>
                        <span class="q-ml-lg">
                          <q-icon class="q-pb-xs-xs" name="subdirectory_arrow_right" /> {{ worker.identifier }}
                        </span>
                      </q-td>

                      <q-td>
                        <span class="q-ml-lg">
                          <template  v-if="worker.startTime && worker.status === 'RUNNING'">
                            <q-icon class="q-pb-xs-xs" name="schedule" /> <date-display :start-time="worker.startTime" offset />
                          </template>
                        </span>
                      </q-td>

                      <q-td colspan="2">
                        <span>
                          <template v-if="worker.status === 'RUNNING'">
                            <q-icon class="q-pb-xs-xs" color="positive" name="check_circle" />
                            {{ $t('dashboard.supervisor.state.' + worker.status.toLowerCase()) }}
                          </template>
                          <template v-if="worker.status !== 'RUNNING'">
                            <q-icon class="q-pb-xs" color="negative" name="error" />
                            {{ $t('dashboard.supervisor.state.' + worker.status.toLowerCase()) }}:
                            <template v-if="!worker.stopTime || worker.status === 'FATAL'">
                              {{ worker.description }}
                            </template>
                            <template v-if="worker.stopTime && worker.status === 'STOPPED'">
                              <date-display :start-time="worker.stopTime" offset />
                            </template>
                          </template>
                        </span>
                      </q-td>
                    </q-tr>
                  </template>

                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card-section>
        <q-card-section class="app-supervisor-action col-auto q-pl-none q-mb-sm q-mx-sm">
          <div class="flex justify-between">
            <q-btn flat dense no-caps icon-right="restart_alt" class="q-pr-sm justify-end app-action-btn"
                   :label="$t('dashboard.supervisor.restartAll')"
                   @click.capture.stop="openRestartWorkerModal()"
                   :disabled="loading"
            />
            <q-btn flat dense no-caps icon-right="refresh" class="q-pr-sm justify-end app-action-btn"
                   :label="$t('dashboard.supervisor.refresh')"
                   @click.capture.stop="getSupervisorData()"
                   :disabled="loading"
            />
          </div>
        </q-card-section>
      </template>
      <q-card-section v-else-if="!loading && !workerRows" class="app-supvervisor-content flex justify-center items-center" data-cy="supervisorContentNoData">
        {{ $t('dashboard.supervisor.noData') }}
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="sass">
</style>
