<script>
  import { defineComponent, ref, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { cronjobService } from 'src/services'
  import { flowService } from 'src/services';
  import cronParser from 'cron-parser';
  import cronstrue from 'cronstrue'
  import 'cronstrue/locales/de';

  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'CronjobModal',

    components: {
      SqDialog
    },

    emits: ['saved'],

    props: ["page", "itemsPerPage", "cronjob"],

    setup (props, { emit }) {
      const $store = useStore();
      const $i18n = useI18n()

      const isLoading = ref(false)
      const selectedFlow = ref(null)
      const cronjobExpression = ref('')
      const cronjobActive = ref(true)

      const cronJobModalLabel = computed(() => $i18n.t(`cronjob.${props.cronjob ? 'updateCronjob' : 'addCronjob'}`))

      function initialize() {
        if (props.cronjob) {
          selectedFlow.value = {
            value: props.cronjob.flow.id,
            label: `Flow ${props.cronjob.flow.id}: ${props.cronjob.flow.name}`
          }
          cronjobExpression.value = props.cronjob.cronExpression
          cronjobActive.value = props.cronjob?.active
        }
      }

      function resetState() {
        selectedFlow.value = null
        cronjobExpression.value = ''
        cronjobActive.value = false
      }

      function saveCronjob() {
        const formData = {
          flow: selectedFlow.value.value,
          cronExpression: cronjobExpression.value,
          active: cronjobActive.value
        }

        isLoading.value = true;

        if (props.cronjob) {
          cronjobService.updateCronjob(props.cronjob, formData, (data) => {
            handleResponse(data);
          })
        } else {
          cronjobService.createCronjob(formData, (data) => {
            handleResponse(data);
          })
        }
      }

      function handleResponse(data) {
        const type = props.cronjob ? 'update' : 'create'

        isLoading.value = false

        if (data?.data['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "cronjob." + type + "CronjobFail", { root: true });
        } else {
          $store.dispatch('alert/success', "cronjob." + type + "CronjobSuccess", {root: true});

          emit('saved', data)

          resetState()
        }
      }

      return {
        isLoading,
        selectedFlow,
        cronjobExpression,
        cronjobActive,
        cronJobModalLabel,

        initialize,
        resetState,
        saveCronjob
      }
    },

    data () {
      return {
        optionFilter: '',
        flows: [],
        isCronValid: true
      }
    },

    computed: {
      options() {
        let optionArray = [];
        if (this.flows?.length) {
          let flowArray = [];
          flowArray.push(...this.flows);
          flowArray.forEach(flow => {
            optionArray.push({value: flow.id, label: "Flow " + flow.id + ": " + flow.name})
          });
          return optionArray.filter(v => v.label.toLowerCase().indexOf(this.optionFilter.toLowerCase()) > -1);
        }
      },

      cronReadable() {
        try {
          cronParser.parseExpression(this.cronjobExpression)
          this.isCronValid = true;
          if(this.$i18n.locale === "de-DE") {
            return cronstrue.toString(this.cronjobExpression, { locale: "de" });
          } else {
            return cronstrue.toString(this.cronjobExpression);
          }
        } catch {
          this.isCronValid = false;
          return this.$t('cronjob.invalidExpression');
        }
      }
    },

    methods: {
      rules (val) {
        if (val.length <= 0) {
          return this.$t('cronjob.form.rules.empty');
        }
      },

      filterFn (val, update) {
        update(() => {
          this.optionFilter = val;
        })
      },

      async handleShow() {
        this.initialize()

        /* cs: as spoken with rh on 09.11.2021, we can make getting flows static. No environment is supposed to have more than 50 flows in the near future */
        this.isLoading = true

        const { data } = await flowService.getFlows(1, 50)

        this.isLoading = false

        if (data?.status === 401) dispatch('authentication/expired', data, {root:true});
        else {
          this.flows = data['hydra:member'];
        }
      }
    }
  })
</script>

<template>
  <sq-dialog
    :type="cronjob ? 'update' : 'create'"
    :loading="isLoading"
    @save="saveCronjob"
    @show="handleShow"
    @hide="resetState"
  >
    <template #title>
      {{ cronJobModalLabel }}
    </template>

    <template #content>
      <q-form @submit="saveCronjob">
        <q-select
          v-model="selectedFlow"
          dense
          use-input
          hide-selected
          fill-input
          :disable="isLoading"
          :options="options"
          :label="$t('cronjob.form.flowId')"
          input-debounce="0"
          class="app-cronjob-flowid-input q-mt-md"
          @filter="filterFn"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                {{ $t('cronjob.noFlows') }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-model:model-value="cronjobExpression"
          required
          dense
          placeholder="* * * * *"
          :label="$t('cronjob.form.expression')"
          :rules="[rules]"
          class="app-cronjob-expression-input q-mt-sm"
        />
        <div
          v-if="cronjobExpression !== ''"
          class="app-cronjob-expression-readable text-h6 text-center"
        >
          {{ cronReadable }}
        </div>

        <q-toggle
          v-model="cronjobActive"
          :label="$t('cronjob.form.activeState')"
          class="app-cronjob-active-toggle q-mt-sm"
        />
      </q-form>
    </template>

    <template #saveButton>
      <q-btn flat
        type="submit"
        color="primary"
        :disabled="!selectedFlow || !cronjobExpression || !isCronValid"
        :label="cronJobModalLabel"
        :class="{ 'disabled': !cronjobExpression }"
        @click="saveCronjob"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  .app-cronjob-name-input, .app-cronjob-mail-input {
    .q-field__native.q-placeholder {
      padding-left: 1rem;
    }
  }
</style>
