<script>
import { defineComponent, ref } from 'vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'ErrorModal',
  components: {
    SqDialog
  },
  props: ['report'],
  data () {
    return {
      isLoading: ref(false),
      errorDetails: ref(null),
      argsExpanded: {},
      errorTrace: ref(null),
      errorStatus: ref(null),
      errorStatusText: ref(null),
      errorResponseURL: ref(null),
      errorResponseMethod: ref(null),
      errorResponseBaseURL: ref(null)
    }
  },
  methods: {
    async handleShow() {
      this.isLoading = true;

      let errorObject = JSON.parse(this.$props.report);

      this.errorTrace = errorObject.data.trace;
      this.errorStatus = errorObject.status;
      this.errorStatusText = errorObject.statusText;
      this.errorResponseURL = errorObject.config.url;
      this.errorResponseMethod = errorObject.config.method;
      this.errorResponseBaseURL = errorObject.config.baseURL;

      this.isLoading = false;
    }

  },
  mounted() {
    localStorage.removeItem("isInternalServerError");
  },
})
</script>

<template>
  <sq-dialog
    :loading="isLoading"
    @show="handleShow"
    type="error"
    size="sm"
  >
    <template v-slot:cancelButton>&#8203;</template> <!-- Can either use an empty template tag or a zero width space (&#8203;) to clear slot -->
    <template v-slot:saveButton>
      <q-btn
          flat
          class="app-action-btn"
          :label="$t('general.close')"
          v-close-popup
      />
    </template>
    <template v-if="this.errorStatus && this.errorStatusText" #title>
      {{ this.errorStatusText }} ({{ this.errorStatus }})
    </template>
    <template v-if="!(this.errorStatus || this.errorStatusText)" #title>
      {{ $t('general.errorModal.unknownErrorHeadline') }}
    </template>

    <template #content>
      <div class="error-modal q-py-md">
        <template v-if="this.errorResponseURL"><strong class="q-pr-xs">URL:</strong>{{ this.errorResponseURL }}<br></template>
        <template v-if="this.errorResponseMethod"><strong class="q-pr-xs">Method:</strong>{{ this.errorResponseMethod }}<br></template>
        <template v-if="this.errorResponseBaseURL"><strong class="q-pr-xs">baseURL:</strong>{{ this.errorResponseBaseURL }}<br></template>

        <q-separator class="q-mt-md q-mb-sm" />

        <h6 class="q-mb-xs q-mt-sm">Trace</h6>
          <template v-if="errorTrace">
          <div class="error-modal-trace" v-for="(trace, i) in errorTrace" :index="i">
            <q-separator v-if="i > 0" class="q-my-sm" />
            <template v-for="(value, k, index) in trace" :key="k" :index="index">
              <div class="error-modal-trace-item" v-if="value">
                <template v-if="k === 'args' && value.length !== 0">
                  <q-expansion-item
                    v-model="argsExpanded[i + '#detailID' + index]"
                    group="argsExpandedGroup"
                    dense
                    switch-toggle-side
                    label="args"
                  >
                    <q-card>
                      <q-card-section>
                        <pre class="q-my-none q-pa-sm">{{ value }}</pre>
                      </q-card-section>
                    </q-card>
                  </q-expansion-item>
                </template>
                <template v-if="k !== 'args'">
                  <strong class="q-pr-xs">{{ k }}:</strong>{{ value }}
                </template>
              </div>
            </template>
          </div>
          </template>
      </div>
    </template>
  </sq-dialog>
</template>
