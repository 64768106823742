<script>
  import { defineComponent } from 'vue'
  import { useQuasar } from 'quasar'
  import DateDisplay from 'components/DateDisplay.vue'
  import StatusBadge from "components/StatusBadge.vue";
  export default defineComponent({
    name: 'FlowItemDetails',
    props: {
      flow: {},
      showElapsedTime: {
        type: Boolean,
        required: false
      },
      flowBlocked: {
        type: Boolean,
        default: false
      }
    },
    components: {
      StatusBadge,
      DateDisplay
    },
    setup () {
        const $q = useQuasar();
        return {
            qInstance: $q
        }
    },
    computed: {
      canGoToLastFlow() {
        return !(this.$props.flowBlocked || !this.flow.lastFlowExecutionId);
      },
      flowCreationTime() {
        return this.flow.lastFlowExecutionCreatedAt;
      },
      lastFlowUpdate() {
        return this.flow.lastFlowExecutionUpdatedAt ?? this.flow.lastFlowExecutioUpdatedAt; // Fallback for old unfixed key
      }
    }
  })
</script>
<template>
    <div v-if="flowCreationTime && lastFlowUpdate" class="q-flow-item-details q-gutter-x-xl row full-width items-center">
      <div class="col-6 col-sm-4 col-md-auto q-ml-none">
        <q-btn
            type="a"
            v-if="flow.lastFlowExecutionId"
            flat dense
            :ripple="canGoToLastFlow"
            class="app-jump-to-execution app-text-weight-semibold text-caption"
            :label="$t('flow.jumpToExecution')"
            icon-right="img:assets/icons/link.svg"
            :title="$t('flow.jumpToExecution', {flowName: flow.name})"
            :disable="!canGoToLastFlow"
            :to="canGoToLastFlow ? '/flowexecutions/detail/' + flow.lastFlowExecutionId : null"
            @click="$event.stopPropagation()"
        />
      </div>
      <div class="col-6 col-sm-4 col-md-auto">
        <q-btn
          type="a"
          v-if="flow.lastFlowExecutionId"
          flat dense
          :ripple="canGoToLastFlow"
          class="app-jump-to-execution app-text-weight-semibold text-caption"
          :label="$t('flow.jumpToExecutionOverview')"
          icon-right="img:assets/icons/link.svg"
          :title="$t('flow.jumpToExecutionOverview')"
          :disable="!canGoToLastFlow"
          :to="canGoToLastFlow ? '/flowexecutions/?flow=' + flow.id : null"
          @click="$event.stopPropagation()"
        />
      </div>
      <div class="col-6 col-sm-4 col-md-auto">
        <span class="row items-center">
          <q-icon class="app-icon-flow-stop" name="stop" size="sm" />
          <div v-if="lastFlowUpdate">
            <date-display :start-time="lastFlowUpdate" only-date />
            <template v-if="showElapsedTime">
              <br><date-display :start-time="lastFlowUpdate" />
            </template>
          </div>
          <span v-else>-</span>
        </span>
      </div>
      <div class="col-6 col-sm-4 col-md-auto">
        <q-icon class="app-icon-flow-stop" name="timer" />
        <date-display
            :start-time="flowCreationTime"
            :end-time="lastFlowUpdate"
        />
      </div>
      <div class="col-6 col-sm-4 col-md-auto">
        <status-badge :running="flow.isRunning" :status="flow.hasFinishedSuccessfully" />
      </div>
    </div>
</template>

<style lang="scss">
 .q-flow-item-details > div {
   @media (max-width: $breakpoint-sm) {
     margin-left: unset
   }
 }
</style>
